import * as API from '@/api/index'

export default {
    //总分配置列表
    skinStandardSumConfig:params =>{
        return API.POST('api/skinStandardSumConfig/all',params)
    },
    //8.2.创建总分配置
    create:params =>{
        return API.POST('api/skinStandardSumConfig/create',params)
    },
    // 修改总分配置
    update:params =>{
        return API.POST('api/skinStandardSumConfig/update',params)
    },
    // 标准状态
    skinCondition:params =>{
        return API.POST("api/skinCondition/all",params)
    },
    // 仪器编号
    skinInstrument:params =>{
        return API.POST("api/skinInstrument/list",params)
    }

}